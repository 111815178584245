/* eslint-disable */

// WARNING: This file needs to be written in Vanilla JS

var Booqable = {
  get location() {
    return window.location.pathname;
  },
  set origin(value) {
    localStorage.setItem('origin', value);
  },
  get origin() {
    return localStorage.getItem('origin');
  }
}

if (window.Booqable === undefined) {
  window.Booqable = Booqable;

  /////////////////////////////////////////////////
  // Delegate initializer
  /////////////////////////////////////////////////

  Booqable.init = function () {
    initBooqableComponents();
  };

  /////////////////////////////////////////////////
  // Set cart data
  /////////////////////////////////////////////////

  Booqable.setCartData = function (data) {
    Booqable.cartData = data;
  };

  /////////////////////////////////////////////////
  // Events
  /////////////////////////////////////////////////

  Booqable._events = {};

  Booqable.on = function (event, callback, context) {
    if (!Booqable._events.hasOwnProperty(event)) {
      Booqable._events[event] = [];
    }

    Booqable._events[event].push([callback, context]);
  };

  Booqable._trigger = function (event) {
    var args = Array.prototype.slice.call(arguments, 1);
    var callbacks = Booqable._events[event] || [];

    for (var i = 0; i < callbacks.length; i++) {
      var callback = callbacks[i][0];
      var context = callbacks[i][1] || Booqable;
      callback.apply(context, args);
    }
  };

  /////////////////////////////////////////////////
  // Script, tag & asset loading
  /////////////////////////////////////////////////

  Booqable.loadScript = function (src) {
    if (Booqable._isScriptLoaded(src)) { return; }

    var tag = document.createElement('script');
    tag.async = false;
    tag.src = src;

    document.getElementsByTagName('head')[0].appendChild(tag);
  };

  /////////////////////////////////////////////////
  // jQuery
  /////////////////////////////////////////////////
  Booqable.jQuery = function (callback) {
    Booqable.loadScript('https://code.jquery.com/jquery-3.3.1.min.js');

    Booqable._defer(function () { return window.$; }, callback);
  };

  /////////////////////////////////////////////////
  // Utilities
  /////////////////////////////////////////////////

  Booqable._isScriptLoaded = function (src) {
    return document.querySelector('script[src="' + src + '"]') ? true : false;
  };

  Booqable._once = function (func) {
    let didRun = false;

    if (typeof func != 'function') {
      throw new TypeError('Expected a function');
    }

    return function() {
      if (!didRun) {
        didRun = true;
        return func.apply(this, arguments);
      } else {
        func = undefined;
      }
    };
  };

  Booqable._defer = function (condition, method, attempt) {
    if (!attempt) {
      attempt = 1;
    }

    if (attempt === 200) {
      return;
    }

    if (condition()) {
      method();
    } else {
      setTimeout(function () { Booqable._defer(condition, method, attempt + 1) }, 50);
    }
  };

  Booqable._prependChild = function (parentElement, element) {
    parentElement.insertBefore(element, parentElement.firstChild);
  };

  /////////////////////////////////////////////////
  // App support
  /////////////////////////////////////////////////
  Booqable._apps = {
    marketing: [],
    essential: [],
  };

  Booqable._initApps = Booqable._once(function () {
    Object.entries(Booqable._apps).forEach(([_, apps]) => {
      apps.forEach((app) => {
        app.init()
      })
    })
  })

  Booqable._consentToAllApps = Booqable._once(function () {
    Object.entries(Booqable._apps).forEach(([_, apps]) => {
      apps.forEach((app) => {
        app.onConsent()
      })
    })
  })

  // Add app to a category.
  Booqable.registerApp = function (category, appConfig) {
    if (!Booqable._apps.hasOwnProperty(category)) {
      throw new Error('Invalid app category \'' + category + "'");
    }

    // We expect apps to define their callbacks, but none
    // are required.
    // name -> name of the app (shown on the cookie notice preferences)
    // init() -> called on window load event
    // onConsent() -> called on window load and when user consents to cookies
    // onDeny() -> called when user denies cookies
    var defaultConfig = {
      name: null,
      init: function () {},
      onConsent: function () {},
      onDeny: function () {}
    }

    Booqable._apps[category].push(Object.assign({}, defaultConfig, appConfig));
  }

  /////////////////////////////////////////////////
  // Google Analytics
  /////////////////////////////////////////////////

  Booqable._gtag = function (type, event, data) {
    if (typeof window.gtag === 'function') {
      window.gtag(type, event, data);
    }
  }

  Booqable._prepare_gtag_event_data = function (data) {
    if (!data.value || !data.currency || !data.items || data.items.length == 0) {
      return null
    }

    return {
      value: data.value,
      currency: data.currency,
      items: data.items.map((item) => ({
        item_id: item.item_id,
        item_name: item.item_name,
        ...(item.quantity && { quantity: item.quantity }),
        price: item.price
      }))
    }
  }

  setupGoogleAnalytics = function (trackingId, defaultPageTracking = true, defaultEvents = false, options = {}) {
    if (!trackingId || trackingId == '') {
      return
    }

    if (typeof options !== 'object') {
      options = {}
    }

    options.linker = { accept_incoming: true }

    Booqable.loadScript('https://www.googletagmanager.com/gtag/js?id=' + trackingId);

    window.dataLayer = window.dataLayer || [];
    window.gtag = function () { dataLayer.push(arguments); }
    window.gtag('js', new Date());
    window.gtag('config', trackingId, options);

    if (defaultPageTracking) {
      Booqable._setupGoogleAnalyticsDefaultPageTracking(trackingId);
    }

    if (defaultEvents) {
      Booqable._setupGoogleAnalyticsDefaultEvents();
    }
  };

  Booqable.setupGoogleAnalytics = Booqable._once(setupGoogleAnalytics)

  _setupGoogleAnalyticsDefaultPageTracking = function (trackingId) {
    Booqable.on('viewCart', function () {
      Booqable._gtag('event', 'page_view', {
        page_title: 'Cart',
        send_to: trackingId
      });

      Booqable._gtag('event', 'view_cart', {
        'currency': Booqable.cartData.currency,
        'value':  Booqable.cartData.grandTotalWithTax,
        'items': Booqable.cartData.items
      });
    });

    Booqable.on('information', function () {
      Booqable._gtag('event', 'page_view', {
        page_title: 'Checkout Information',
        send_to: trackingId
      });
    });

    Booqable.on('payment', function () {
      Booqable._gtag('event', 'page_view', {
        page_title: 'Checkout Payment',
        send_to: trackingId
      });
    });

    Booqable.on('completed', function () {
      Booqable._gtag('event', 'page_view', {
        page_title: 'Checkout Completed',
        send_to: trackingId
      });
    });
  };

  Booqable._setupGoogleAnalyticsDefaultPageTracking = Booqable._once(_setupGoogleAnalyticsDefaultPageTracking)

  _setupGoogleAnalyticsDefaultEvents = function () {
    // Shop Events
    Booqable.on('viewProduct', function (data) {
      const event_data = Booqable._prepare_gtag_event_data(data)

      Booqable._gtag('event', 'view_item', event_data);
    });

    Booqable.on('addToCart', function (data) {
      const event_data = Booqable._prepare_gtag_event_data(data)

      Booqable._gtag('event', 'add_to_cart', event_data);
    });

    Booqable.on('removeFromCart', function (data) {
      const event_data = Booqable._prepare_gtag_event_data(data)

      Booqable._gtag('event', 'remove_from_cart', event_data);
    });

    // Checkout events
    Booqable.on('information', function () {
      // Regular event
      Booqable._gtag('event', 'Checkout', {
        'event_category': 'Information',
        'event_label': 'Information'
      });

      // Ecommerce event
      Booqable._gtag('event', 'begin_checkout', {
        'currency': Booqable.cartData.currency,
        'value':  Booqable.cartData.grandTotalWithTax,
        'coupon': Booqable.cartData.coupon,
        'items': Booqable.cartData.items
      });
    });

    Booqable.on('payment', function () {
      // Regular event
      Booqable._gtag('event', 'Checkout', {
        'event_category': 'Payment',
        'event_label': 'Payment'
      });
    });

    Booqable.on('completed', function () {
      // Regular event
      Booqable._gtag('event', 'Checkout', {
        'event_category': 'Completed',
        'event_label': 'Completed'
      });

      // Ecommerce event
      Booqable._gtag('event', 'purchase', {
        "transaction_id": Booqable.cartData.cartId,
        "value": Booqable.cartData.grandTotalWithTax,
        "currency": Booqable.cartData.currency,
        "tax": Booqable.cartData.tax,
        "items": Booqable.cartData.items,
        "coupon": Booqable.cartData.coupon
      });
    });
  }

  Booqable._setupGoogleAnalyticsDefaultEvents = Booqable._once(_setupGoogleAnalyticsDefaultEvents)

  /////////////////////////////////////////////////
  // Facebook
  /////////////////////////////////////////////////

  Booqable._fbq = function (type, event, data) {
    if (typeof window.fbq === 'function') {
      window.fbq(type, event, data);
    }
  }

  Booqable._prepare_fbq_event_data = function (data) {
    const item = data.items[0]

    if (!data.currency || !item) {
      return null
    }

    return {
      contents: [{
        id: item.item_id,
        quantity: item.quantity
      }],
      content_name: item.item_name,
      currency: data.currency,
      value: item.price
    }
  }

  setupFacebook = function (fbId, defaultEvents = true) {
    if (!fbId || fbId == '') { return ; }

    (function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
      n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
      document,'script','https://connect.facebook.net/en_US/fbevents.js'));

    fbq('init', fbId);

    if (defaultEvents) {
      Booqable._setupFacebookDefaultEvents();
    }
  };

  Booqable.setupFacebook = Booqable._once(setupFacebook)

  _setupFacebookDefaultEvents = function () {
    Booqable.on('information', function () {
      Booqable._fbq('track', 'InitiateCheckout', {
        value: Booqable.cartData.grandTotalWithTax,
        currency: Booqable.cartData.currency
      });
    });

    Booqable.on('payment', function () {
      Booqable._fbq('track', 'AddPaymentInfo');
    });

    Booqable.on('completed', function () {
      Booqable._fbq('track', 'Purchase', {
        value: Booqable.cartData.grandTotalWithTax,
        currency: Booqable.cartData.currency
      });
    });

    Booqable.on('page-change', function () {
      Booqable._fbq('track', 'PageView')
    });

    Booqable.on('viewProduct', function (data) {
      const event_data = Booqable._prepare_fbq_event_data(data)

      Booqable._fbq('track', 'ViewContent', event_data);
    });

    Booqable.on('addToCart', function (data) {
      const event_data = Booqable._prepare_fbq_event_data(data)

      Booqable._fbq('track', 'AddToCart', event_data);
    });

    Booqable.on('removeFromCart', function (data) {
      const event_data = Booqable._prepare_fbq_event_data(data)

      Booqable._fbq('trackCustom', 'RemoveFromCart', event_data);
    });
  };

  Booqable._setupFacebookDefaultEvents = Booqable._once(_setupFacebookDefaultEvents)

  /////////////////////////////////////////////////
  // Intercom
  /////////////////////////////////////////////////

  Booqable.setupIntercom = function (appId) {
    window.intercomSettings = {
      app_id: appId
    };

    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');
    ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];
    i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');
    s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/frrez76q';
    var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}
    if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}
    Booqable._loadIntercom=l;}})()

    Booqable._once(Booqable._loadIntercom)();
  };

  /////////////////////////////////////////////////
  // Properties & Property updaters
  /////////////////////////////////////////////////

  Booqable.decorateLinkForTracking = function (link) {
    // https://developers.google.com/analytics/devguides/collection/gtagjs/cross-domain
    if (window.ga) {
      var trackers = window.ga.getAll && window.ga.getAll()
      var tracker = trackers && trackers[0]

      if (tracker && window.gaplugins && window.gaplugins.Linker) {
        var linker = new window.gaplugins.Linker(tracker)

        link = linker.decorate(link)
      }
    }

    return link
  }

  /////////////////////////////////////////////////
  // Button utilities
  /////////////////////////////////////////////////

  document.querySelectorAll('form').forEach((form) => {
    form.addEventListener('submit', function (e) {
      const button = e.submitter
      if (button?.classList.contains('button--with-loading')) {
        button.setAttribute('disabled', 'disabled')
      }
    })
  })
}
